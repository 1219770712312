<template>
  <div>
    <h1 class="h3 mb-4">
      {{ $t('productAvailability.productAvailability') }}
    </h1>
    <a-row :gutter="16">
      <a-col v-if="restriction === 'Business'" :span="6">
        <a-select 
          v-if="restriction === 'Business'"
          v-model="params.warehouse_id" 
          allow-clear
          show-search
          size="large" 
          class="w-100 mt-4 select-antd-default" 
          option-filter-prop="children"
          :filter-option="filterOption"
          :placeholder="$t('topBar.all_Warehouse')"
          :options="warehouseList"
          :disabled="isLoading"
          @change="fetchInventory"
        />
      </a-col>
      <a-col :span="8">
        <a-input-search
          v-model="params.search" 
          allow-clear
          size="large"
          class="w-100 mt-4"
          :enter-button="$t('utils.search')"
          :loading="isLoading"
          :disabled="isLoading"
          :placeholder="`${$t('productAvailability.productName')} / SKU`"
          @search="onSearchList"
        />
      </a-col>
      <!-- For next development -->
      <!-- <a-col :span="4">
        <label class="mt-4 mb-1">Sort by</label>
        <a-select 
          v-model="sortBy.sort" 
          size="large" 
          class="w-100 select-antd-default" 
          :options="sorts"
        />
      </a-col>
      <a-col :span="3">
        <label class="mt-4 mb-1">Order by</label>
        <a-select v-model="sortBy.order" size="large" class="w-100 select-antd-default">
          <a-select-option value="Asc">
            Asc
          </a-select-option>
          <a-select-option value="Desc">
            Desc
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <label class="mb-1">&nbsp;</label>
        <a-button 
          type="primary" 
          size="large" 
          icon="search" 
          class="w-100 mt-4" 
          @click="fetchInventory"
        >
          {{ $t('utils.search') }}
        </a-button>
      </a-col>
      <a-col :span="3">
        <label class="mb-1">&nbsp;</label>
        <a-button 
          size="large" 
          class="w-100 mt-4" 
          @click="resetFilter"
        >
          Reset
        </a-button>
      </a-col> -->
    </a-row>

    <div class="d-flex justify-start align-items-center mt-4" style="gap: 10px">
      <a-button
        type="primary"
        @click.prevent="showModalExportExcel = true"
      >
        {{ $t('warehouse.export_data') }}
      </a-button>
    </div>

    <a-tabs
      v-model="params.status"
      size="large"
      class="pca-tab-store mt-4"
      @change="onChangeStatus"
    >
      <a-tab-pane
        v-for="tab in tabs"
        :key="tab.key"
        :tab="`${tab.title} (${statusCount[tab.key]})`"
        :disabled="isLoading"
      />
    </a-tabs>
    <a-table
      class="inventory-list"
      :data-source="inventories"
      :row-key="record => record.id"
      :columns="columns"
      :loading="isLoading"
      :pagination="false"
      :scroll="scroll"
    >
      <template slot="title_availability">
        <div class="d-flex align-items-center">
          <div class="mr-1">
            Dapat Dijual
          </div>
          <a-tooltip title="Stok yang dapat dijual dan muncul di channel penjualan">
            <a-icon type="info-circle" style="font-size: 14px;" />
          </a-tooltip>
        </div>
      </template>
      <template slot="title_in_order">
        <div class="d-flex align-items-center">
          <div class="mr-1">
            {{ $t('productAvailability.inorder') }}
          </div>
          <a-tooltip title="Stok pesanan dalam proses, sebelum dilakukan good issued">
            <a-icon type="info-circle" style="font-size: 14px;" />
          </a-tooltip>
        </div>
      </template>
      <template slot="title_on_hand">
        <div class="d-flex align-items-center">
          <div class="mr-1">
            {{ $t('productAvailability.warehouseStock') }}
          </div>
          <a-tooltip title="Stok yang berstatus available (termasuk stok yang dapat dijual maupun on order)">
            <a-icon type="info-circle" style="font-size: 14px;" />
          </a-tooltip>
        </div>
      </template>
      <template slot="sku" slot-scope="text, record">
        <a-tooltip v-if="(record.title && record.title.length > 29) || (record.sku && record.sku.length + 5) > 29">
          <template slot="title">
            {{ record.title }} <br />
            {{ `SKU: ${record.sku}` }}
          </template>
          <span class="list-title text-blue-2">{{ ellipsisText(record.title) }}</span>
          <br />
          <span class="list-info text-gray-7">
            {{ ellipsisText('SKU: ' + record.sku) }}
          </span>
        </a-tooltip>
        <span v-else>
          <span class="list-title text-blue-2">{{ record.title }}</span>
          <br />
          <span class="list-info text-gray-7">{{ record.sku }}</span>
        </span>
      </template>
      <template slot="warehouse_id" slot-scope="text, record">
        <a-tooltip v-if="getWarehouseName(text).length > 29 || (record.business_name && record.business_name.length > 29)">
          <template slot="title">
            {{ getWarehouseName(text) }} <br />
            {{ record.business_name }}
          </template>
          <span>{{ ellipsisText(getWarehouseName(text)) }}</span>
          <br />
          <span class="list-info mb-0 text-gray-7">
            {{ ellipsisText(record.business_name) }}
          </span>
        </a-tooltip>
        <span v-else>
          <span>{{ getWarehouseName(text) }}</span> <br />
          <span class="list-info mb-0 text-gray-7">
            {{ record.business_name }}
          </span>
        </span>
      </template>
      <template slot="availability" slot-scope="text">
        <span :class="{ minus: +text < 0 }">{{ `${text}` }}</span>
      </template>
      <template slot="on_hand" slot-scope="text">
        <p class="mb-1">
          {{ `${text < 0 ? 0 : text}` }}
        </p>
        <!-- FIXME: hide allocatable & allocated -->
        <!-- <p class="mb-1">
          {{ `${$t('productAvailability.allocable')}: ${record.allocatable}` }}
        </p>
        <p class="mb-0">
          {{ `${$t('productAvailability.allocated')}: ${record.allocated}` }}
        </p> -->
      </template>
      <template slot="allocatable" slot-scope="text, record">
        <a-tag :color="colors[getStatus(record.availability)]">
          {{ $t(`productAvailability.${getStatus(record.availability)}`) }}
        </a-tag>
      </template>
      <!-- For next development -->
      <template slot="action" slot-scope="text, record">
        <a-dropdown>
          <a @click.prevent="">
            <a-icon type="more" />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="1" class="py-2" @click="() => navigateToDetailStockMovement(record)">
                <a-icon type="eye" />
                {{ $t('store_list_page.view_detail') }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.size"
        :page-size-options="sizeOptions"
        :total="totalRow"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <ModalExportExcel
      :warehouse-list="warehouseList"
      :tab-status="tabs"
      :visible="showModalExportExcel"
      :on-close="onCloseModalExportExcel"
      :is-loading="loadingExport"
      @handleSubmitModal="exportDataTable"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { columnsStock } from '@/data/columns'
import { getInventoryList } from '@/api/inventory'
import ModalExportExcel from '@/views/inventory/inventory-v2/ModalExportExcel.vue'
import XLSX from 'xlsx';

export default {
  components: {
    ModalExportExcel,
  },
  data() {
    return {
      columns: columnsStock,
      isLoading: false,
      totalRow: 10,
      statusCount: {
        ALL: 0,
        IN_STOCK: 0,
        OUT_OF_STOCK: 0,
        MINUS_OF_STOCK: 0,
      },
      params: { 
        warehouse_id: this.$store.state.user.restriction_base === 'Warehouse' 
          ? this.$route.query.warehouse_id : '',
        search: '',
        status: 'ALL',
        sort: '',
        page: 1,
        size: 10,
      },
      sortBy: {
        sort: 'updatedAt',
        order: 'Desc',
      },
      warehouseList: [],
      sorts: [],
      sizeOptions: ['10', '20', '30', '40', '50'],
      colors: {
        IN_STOCK: 'blue',
        OUT_OF_STOCK: 'orange',
        MINUS_OF_STOCK: 'red',
      },
      tabs: [
        { title: this.$t('utils.all'), key: 'ALL' },
        { title: this.$t('productAvailability.IN_STOCK'), key: 'IN_STOCK' },
        { title: this.$t('productAvailability.OUT_OF_STOCK'), key: 'OUT_OF_STOCK' },
        // { title: this.$t('productAvailability.MINUS_OF_STOCK'), key: 'MINUS_OF_STOCK' },
      ],
      inventories: [],
      showModalExportExcel: false,
      loadingExport: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    restriction() {
      return this.$store.state.user.restriction_base
    },
    screenHeight() {
      return ((screen.height - 900) * 0.4) + (screen.height * 0.4)
    },
    scroll() { 
      return { y: this.screenHeight > 360 ? this.screenHeight : 360, x: 1000 }
    },
    tHeight() {
      return !this.inventories.length ? '0px' : this.screenHeight > 360 
        ? `${this.screenHeight}px` : '360px'
    },
    pHeight() {
      return this.inventories && this.inventories.length ? '0px' : this.screenHeight > 360 
        ? `${this.screenHeight}px` : '360px'
    },
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
  },
  watch: {
    '$route.query'() {
      this.fetchWarehouseList()
    },
    businessId() {
      this.fetchWarehouseList()
    },
    // permission(newValue) {
    //   if(!newValue.length) {
    //    this.$router.push({ path: '/error/403', query: {...this.$route.query } })
    //   }
    // },
  },
  mounted() {
    this.fetchWarehouseList()
  },
  methods: {
    navigateToDetailStockMovement(catalog) {
      this.$router.push({
        path: `/inventory/detail-stock-movement/${catalog.sku}`,
        query: {
          ...this.$route.query,
          warehouse_id: catalog.warehouse_id,
        },
      })
    },
    onCloseModalExportExcel() {
      this.showModalExportExcel = false
    },
    exportDataTable(data) {
      if (data.totalData > 0) {
        this.loadingExport = true
        const payload = {
          warehouse_id: data.warehouse,
          status: data.statusStock,
          page: 1,
          size: data.totalData,
          business_id: data.businessId,
        }
        getInventoryList(payload)
        .then(({ data }) => {
          const flattenData = data.content.map((obj) => ({
            title: obj.title,
            sku: obj.sku,
            business_name: obj.business_name,
            warehouse_name: this.getWarehouseName(obj.warehouse_id),
            availability: obj.availability,
            in_order: obj.in_order,
            on_hand: obj.on_hand,
            unit: obj.unit,
            status: this.$t(`productAvailability.${this.getStatus(obj.availability)}`),
          }))
          const rowData = [
            "Nama Produk",
            "SKU",
            "Nama Bisnis",
            "Nama Gudang",
            "Dapat Dijual",
            "On Order",
            "Stok Gudang",
            "Satuan Dasar",
            "Status",
          ]
          flattenData.sort((a, b) => {
            return this.$moment(b.paid_at) - this.$moment(a.paid_at);
          })
          const worksheet = XLSX.utils.json_to_sheet(flattenData);
          const workbook = XLSX.utils.book_new()
          XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
          XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
          XLSX.writeFile(workbook,'daftar_persediaan.xlsx')
        })
        .catch(err => {
          this.loadingExport = false
          console.error(err)
        })
        .finally(() => {
          this.loadingExport = false
          this.showModalExportExcel = false
        })
      } else {
        this.$notification.error({
          message: 'Tidak ada data berdasarkan filter yang dipilih.',
        })
        this.showModalExportExcel = false
      }
    },
    toCamelCase(str) {
      return str.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase().replace('_', '')
      })
    },
    ellipsisText(text) {
      if(!text) return '-'
      return text.length > 49 ? `${text.substring(0, 46)}...` : text
    },
    getWarehouseName(id) {
      if(!id) return '-'
      const findWarehouse = this.warehouseList.find(item => item.value === id)
      return findWarehouse?.label || '-'
    },
    getStatus(stock) {
      if(this.params.status === 'ALL') {
        return +stock > 0
          ? 'IN_STOCK'
          : +stock == 0
          ? 'OUT_OF_STOCK'
          : 'MINUS_OF_STOCK'
      }
      return this.params.status
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    resetFilter() {
      this.params = { 
        warehouse_id: this.$store.state.user.restriction_base === 'Warehouse' 
          ? this.$route.query.warehouse_id : '',
        search: '',
        status: 'ALL',
        sort: '',
        page: 1,
        size: 10,
      }
      this.sortBy = {
        sort: 'updatedAt',
        order: 'Desc',
      }
      this.fetchInventory()
    },
    onSearchList() {
      this.params.page = 1
      this.fetchInventory()
    },
    onChangeStatus(tab) {
      this.params.page = 1
      this.params.status = tab
      this.fetchInventory()
    },
    onPageChange(pageNumber) {
      this.params.page = pageNumber
      this.fetchInventory()
    },
    onShowSizeChange(current, pageSize) {
      this.params.page = current
      this.params.size = pageSize
      this.fetchInventory()
    },
    fetchWarehouseList: debounce(async function() {
      this.isLoading = true
      await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
          business_id: this.businessId,
          warehouse_id: this.$store.state.user.restriction_base === 'Warehouse' 
            ? this.$route.query.warehouse_id
            : undefined,
        })
        .then(response => {
          this.warehouseList = response.length == 1
            ? response
            : [{ value: '', label: this.$t('topBar.all_Warehouse') }].concat(response)
          this.params.warehouse_id = response.length == 1
            ? response[0].value
            : ''
        })
        .catch(err => {
          this.warehouseList = [{ value: '', label: this.$t('topBar.all_Warehouse') }]
          this.params.warehouse_id = ''
          console.error(err)
        })
        .finally(() => this.fetchInventory())
    }, 500),
    async fetchInventory() {
      this.isLoading = true

      let bodyValue = {}
      for(const prop in this.params) {
        const value = prop === 'sort' ? `${this.sortBy.sort},${this.sortBy.order}` : this.params[prop]
        if(value) bodyValue = { ...bodyValue, [prop]: value }
      }
      bodyValue.business_id = this.businessId
      
      await getInventoryList(bodyValue)
        .then(({ data }) => {
          this.inventories = data.content
          this.totalRow = data.total_of_elements
          this.statusCount = data.summary
          if(!this.inventories.length) this.sortBy.sort = ''
          for(const prop in this.inventories[0]) {
            if(prop !== 'id') {
              const value = this.toCamelCase(prop)
              this.sorts.push({
                value,
                label: this.$t(`productAvailability.${value}`),
              })
            }
          }
        })
        .catch(err => {
          this.totalRow = 10
          this.sortBy.sort = ''
          this.statusCount = {
            ALL: 0,
            IN_STOCK: 0,
            OUT_OF_STOCK: 0,
            MINUS_OF_STOCK: 0,
          }
          this.inventories = []
          this.sorts = []
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
  },
}
</script>

<style lang="scss">
.inventory-list {
  div {
    .ant-table-body {
      min-height: v-bind(tHeight);
    }
    .ant-table-placeholder {
      min-height: v-bind(pHeight);
    }
  }
  .list-title {
    font-weight: bold;
  }
  .list-info {
    font-size: 12px;
  }
}
.minus {
  color: #ff0a54;
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>
