<template>
  <a-modal :visible="visible" :wrap-class-name="$style.modalSync" :closable="false">
    <p class="mt-2">
      Export Excel
    </p>
    <div class="mt-3">
      <label>Bisnis</label>
      <a-select
        v-model="selectedBusiness"
        show-search
        :filter-option="filterOption"
        :show-arrow="!$route.query.business_id"
        :disabled="!!$route.query.business_id"
        class="w-100 select-antd-default filter"
      >
        <a-select-option value="" selected hidden>
          Pilih bisnis
        </a-select-option>
        <a-select-option v-for="(item, index) in businessOptions" :key="index" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <div class="mt-4">
      <label>Warehouse</label>
      <a-select 
        v-model="selectedWarehouse" 
        allow-clear
        show-search
        class="w-100 select-antd-default" 
        option-filter-prop="children"
        :filter-option="filterOption"
        :placeholder="$t('topBar.all_Warehouse')"
        :options="warehouseList"
      />
    </div>
    <div class="mt-4">
      <label>Status</label>
      <a-select
        v-model="selectedStatus"
        allow-clear
        show-search
        :filter-option="filterOption"
        class="w-100 select-antd-default filter"
      >
        <a-select-option value="" selected hidden>
          Pilih Status
        </a-select-option>
        <a-select-option v-for="(item, index) in tabStatus" :key="index" :value="item.key">
          {{ item.title }}
        </a-select-option>
      </a-select>
    </div>
    <template #footer>
      <a-button key="back" :disabled="isLoading" class="mr-2" @click="typeof back !== 'undefined' && back(), resetData(), onClose()">
        Cancel
      </a-button>
      <a-button
        key="submit"
        :loading="isLoading"
        :disabled="isLoading || !selectedBusiness || !(selectedWarehouse || selectedWarehouse === '') || !selectedStatus"
        type="primary"
        @click="onSubmitModal()"
      >
        Export Excel
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import { getInventoryList } from '@/api/inventory'

export default {
  name: 'ModalExportExcel',
  components: {
  },
  mixins: [getQueryParam],
  props: {
    movementStatus: Array,
    warehouseList: Array,
    tabStatus: Array,
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
  },
  data() {
    return {
      selectedBusiness: '',
      selectedWarehouse: '',
      selectedStatus: undefined,
      statusMovement: [],
      totalRowData: 0,
    }
  },
  computed: {
    integration() {
      return this.$store.state.integration
    },
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    businessOptions() {
      let list = []
      // const value = this.$store.state.user.restriction_base === 'Business'
      //   ? 'business_id'
      //   : 'warehouse_id'
      // const label = this.$store.state.user.restriction_base === 'Business'
      //   ? 'business_name'
      //   : 'name'
      this.$store.state.user.businessList.forEach(business => {
        if(business.business_id !== 'all') {
          const findSameId = list.find(item => item.value === business.business_id)
          if (!findSameId) {
            list.push({
              value: business.business_id,
              label: business.business_name,
            })
          }
        }
      })
      return list
    },
  },
  watch: {
    'visible': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (!!value) {
          this.selectedBusiness = this.$route.query.business_id ?? ''
        }
      },
    },
    'selectedStatus': {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.selectedBusiness && (this.selectedWarehouse || this.selectedWarehouse === '') && this.selectedStatus) {
          this.onCalculateRowData()
        }
      },
    },
    'selectedBusiness': {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.selectedBusiness && (this.selectedWarehouse || this.selectedWarehouse === '') && this.selectedStatus) {
          this.onCalculateRowData()
        }
      },
    },
    'selectedWarehouse': {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.selectedBusiness && (this.selectedWarehouse || this.selectedWarehouse === '') && this.selectedStatus) {
          this.onCalculateRowData()
        }
      },
    },
  },
  methods: {
    to(url) {
      const route = typeof url === 'object' ? url : { path: url }
      route.query = {
        ...this.to.query,
        ...this.getQueryParam({ ...this.$route.query }),
        ...url.params?.query,
      }
      
      return route
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    onSubmitModal() {
      const selectedData = {
        warehouse: this.selectedWarehouse,
        statusStock: this.selectedStatus,
        businessId: this.selectedBusiness,
        totalData: this.totalRowData,
      }
      this.$emit('handleSubmitModal', selectedData)
      this.resetData()
    },
    resetData() {
      this.selectedWarehouse = ''
      this.selectedBusiness = ''
      this.selectedStatus = undefined
    },
    async onCalculateRowData() {
      const payload = {
        warehouse_id: this.selectedWarehouse,
        status: this.selectedStatus,
        page: 1,
        size: 1,
        business_id: this.selectedBusiness,
      }
      const { data } = await getInventoryList(payload)
      this.totalRowData = data.total_of_elements || 0
    },
  },
}
</script>

<style lang="scss" module>
// @import './style.module.scss';
</style>